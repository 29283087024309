export const componentsES = {
  COURSES: {
    start: 'Comenzar',
    name: 'CURSOS',
    interactive: 'interactivos de {{model}}'
  },
  EVALUATIONS: {
    title: 'Evaluaciones',
    subtitle: 'Lo último',
    notavailable: 'No hay evaluaciones disponibles',
    rows: {
      category: 'Categoría',
      date: 'Fecha',
      status: 'Estado',
      options: 'Opción'
    }
  },
  BANNER: {
    unblock: 'Desbloquear',
    details: 'Ver Paquetes',
    unblocked: '¡Felicidades has desbloqueado el paquete {{package}}, disfruta!'
  },
  EXAMS: {
    title: '¡EXAMENES!',
    description: '¡Comienza a {{practice}} {{model}} ya!',
    practice: 'praticar para el'
  },
  RECORDER: {
    unsync: 'Habilite su micrófono para poder grabar',
    play: 'Presiona para grabar',
    stop: 'Presiona para detener'
  },

  FOOTER: {
    match: 'Respuesta correcta'
  },
  SPEAKING: {
    next: 'Siguiente Pregunta',
    stepOne: '¡Muy bien, podemos continuar!',
    stepThree: '¡Bien hecho!',
    stepTwo: '¡Completado envia tus resultados ahora!'
  },
  payment: {
    cancel: 'Cancelar',
    action: 'Realizar Pago',
    content: 'Ver detalles',
    details: '¿Desea realizar la compra del paquete {{plan}}?',
    invoice: 'Generando recibo..',
    placeholder: 'Inserta los datos de tu tarjeta de crédito',
    processing: 'Se está procesando el pago para {{model}}',
    subscribe: 'Subscribete para ver resultados'
  },
  chat: {
    isWriting: '{user} is writing..',
    placeholder: 'Write a message..'
  },
  MEDIA_STREAM_ERROR: {
    line1:
      'B1B2top no ha podido conectar su videollamada, no se encuentra su dispositivo.',
    line2: 'Asegúrese que',
    line3: 'Su cámara web está conectada.',
    line4: 'Compruebe que su micrófono está conectado',
    line5:
      'Una vez que haya comprobado su cámara y su micrófono, actualice la página.',
    line6:
      'Si lo precisa, puede utilizar el cuadro de chat para notificar a su profesor que tiene problemas de conexión.',
    reconnect: 'Reconectar'
  },
  NOTIFICATIONS: {
    title: 'Notificaciones',
    unavailable: 'No tienes notificaciones disponibles',
    goto: 'Ir a notificaciones'
  },
  NAVIGATION: {
    exam: 'Examenes',
    config: 'Configuración',
    logout: 'Cerrar Sesion',
    select: 'Cambiar App'
  },
  audioRecorder: {
    recording: 'Grabando Respuesta',
    unsyncMic: 'Habilite su micrófono para poder grabar',
    recordToContinue: 'Para continuar graba tu respuesta',
    completedRecords: '¡Muy bien has completado tus grabaciones!'
  },
  completedExercise: {
    review: 'Resumen'
  },
  completedUnit: {
    placeholder: {
      congrats: '¡Felicidades, unidad completada!',
      effort: 'Estás haciendo un gran esfuerzo',
      results: 'Y todos los esfuerzos tienen sus resultados.',
      machine: '¡Genial, eres una máquina!'
    }
  }
}
