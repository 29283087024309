export const authenticationEN = {
  demo: 'Access without an account',
  email: 'Email:',
  error:
    'An error has occurred, we recommend you try logging in with your credentials.',
  forgot: 'Did you forget your password?',
  google: 'Access with Google',
  login: 'Log in',
  password: 'Password:',
  quick: 'Quick Access',
  sign: 'Sign up',
  terms: 'Terms and conditions',
  accept: 'Accept terms and conditions',
  welcome: 'Welcome!',
  confirm: 'Confirm password',
  firstName: 'Name',
  lastName: 'Last name',
  register: 'Sign up',
  verify: 'Your account has been verified!',
  form: {
    placeholder: {
      emailShort: 'Email',
      firstName: 'Name',
      lastName: 'Last name'
    }
  },
  validation: {
    password: 'Your password must contain at least 8 characters',
    name: 'The name or last name are not valid',
    email: 'The email address is not valid'
  },
  mustAcceptTerms: 'You must accept the general terms to continue',
  passwordMustMatch: 'Password Must Match',
  update: 'Update',
  updateQuickPassword: 'Password has been updated',
  updateFailedPassword: 'Password cannot be updated'
}
