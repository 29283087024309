import { agreementEN } from 'lang/data/agreement/en'
import { allEN } from 'lang/data/all/en'
import { authenticationEN } from 'lang/data/authentication/en'
import { classesEN } from 'lang/data/classes/en'
import { componentsEN } from 'lang/data/components/en'
import { coursesEN } from 'lang/data/courses/en'
import { dashboardEN } from 'lang/data/dashboard/en'
import { defaultEN } from 'lang/data/default/en'
import { evaluationsEN } from 'lang/data/evaluations/en'
import { examsEN } from 'lang/data/exams/en'
import { exchangeEN } from 'lang/data/exchange/en'
import { feedbackEN } from 'lang/data/feedback/en'
import { forgotPasswordEN } from 'lang/data/forgot-password/en'
import { loadingIndicatorEN } from 'lang/data/loadingIndicator/en'
import { mailsEN } from 'lang/data/mails/en'
import { meetingsEN } from 'lang/data/meetings/en'
import { modalEN } from 'lang/data/modal/en'
import { modelsEN } from 'lang/data/models/en'
import { navigationEN } from 'lang/data/navigation/en'
import { notificationsEN } from 'lang/data/notifications/en'
import { paymentsEN } from 'lang/data/payments/en'
import { plansEN } from 'lang/data/plans/en'
import { qualityEN } from 'lang/data/quality/en'
import { reportEN } from 'lang/data/report/en'
import { resetPasswordEN } from 'lang/data/resetPassword/en'
import { settingsEN } from 'lang/data/settings/en'
import { signUpEN } from 'lang/data/signUp/en'
import { statsEN } from 'lang/data/stats/en'
import { textEN } from 'lang/data/text/en'
import { toastNotificationEN } from 'lang/data/toastNotification/en'
import { tourEN } from 'lang/data/tour/en'

const en = {
  AGREEMENT: agreementEN,
  ALL: allEN,
  AUTHENTICATION: authenticationEN,
  CLASSES: classesEN,
  COMPONENTS: componentsEN,
  COURSES: coursesEN,
  DASHBOARD: dashboardEN,
  DEFAULT: defaultEN,
  EVALUATIONS: evaluationsEN,
  EXAMS: examsEN,
  EXCHANGE: exchangeEN,
  FEEDBACK: feedbackEN,
  FORGOT: forgotPasswordEN,
  LOADING_INDICATOR: loadingIndicatorEN,
  MAILS: mailsEN,
  MEETING: meetingsEN,
  MODAL: modalEN,
  MODELS: modelsEN,
  NAVIGATION: navigationEN,
  NOTIFICATIONS: notificationsEN,
  PAYMENTS: paymentsEN,
  PLANS: plansEN,
  QUALITY: qualityEN,
  REPORT: reportEN,
  RESET_PASSWORD: resetPasswordEN,
  SETTINGS: settingsEN,
  SIGN_UP: signUpEN,
  TEXT: textEN,
  TOAST_NOTIFICATION: toastNotificationEN,
  TOUR: tourEN,
  STATS: statsEN
}

export default en
