import httpClient, { GET, PUT } from 'providers/http'

/**
 * @param {{ page?: number, signal: AbortSignal }}
 */
export function fetchNotifications ({ page, signal }) {  
  if (page) {
    return httpClient({
      endpoint: '/api/v1/notifications/all',
      method: GET,
      queries: {
        page
      },
      requiresAuth: true,
      signal
    })
  }

  return httpClient({
    endpoint: '/api/v1/notifications/all',
    method: GET,
    queries: {
      unreads: true
    },
    requiresAuth: true,
    signal
  })
}


export function markAsReadNotification ({ id }) {
  return httpClient({
    body: {
      read: true
    },
    endpoint: '/api/v1/notifications',
    method: PUT,
    params: [
      id
    ],
    requiresAuth: true
  })
}

export function markAllAsRead () {
  return httpClient({
    endpoint: '/api/v1/notifications/all',
    method: PUT,
    requiresAuth: true
  })
}