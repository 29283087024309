import httpClient, { GET } from 'providers/http'

/**
 * @param {{ role: number, page: number, search }}
 */
export async function fetchUsers ({ role, page, search, signal }) {
  return httpClient({
    endpoint: '/api/v1/users',
    method: GET,
    queries: {
      role,
      page,
      search
    },
    requiresAuth: true,
    signal
  })
}