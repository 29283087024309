import React from 'react'
import { useTranslation } from 'react-i18next'

import Text from 'components/Text'

const TermsAndConditions = () => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <Text tag="small" color="muted" center>
        {t('TERMS.data')}
        <Text className="mx-1" tag="a" href="https://play.b1b2.top/" color="info">
          https://play.b1b2.top/
        </Text>
        academyb1b2@gmail.com
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        1. Acceso a la Plataforma Las presentes condiciones generales (en
        adelante, las “Condiciones Generales”) regulan el servicio de cursos de
        inglés on-line que ofrece B1 B2 Academy (en adelante, el “Servicio” o
        “B1 B2 Academy”) en su plataforma tecnológica accesible a través de su
        aplicación móvil y sitio web (en adelante, la “Plataforma”). Mediante el
        acceso a la Plataforma “AptisGo”, los Estudiantes que se den de alta (en
        adelante, los “Estudiantes”) tendrán acceso a los cursos de inglés
        on-line disponibles en cada momento. La persona que acceda a la
        Plataforma con el fin de contratar productos o servicios disponibles en
        la misma, asegura que tiene capacidad legal para ello.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        2.El Objeto
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        2.1 Se consideran productos o servicios disponibles en la Plataforma,
        todos los ofertados en la Plataforma.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        2.2. Mediante la contratación de productos o servicios disponibles en la
        Plataforma, el Cliente acepta expresamente todas las cláusulas de estas
        condiciones generales de contratación, de su aviso legal, de su política
        de privacidad, y de su política de cookies, así como de todas aquellas
        condiciones particulares recogidas para la contratación de determinados
        productos o servicios disponibles en la Plataforma. En caso de no
        aceptar alguna de las citadas cláusulas, el Cliente deberá abstenerse de
        contratar productos o servicios en la Plataforma.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        2.3 Algunos de los servicios ofertados pueden estar sujetos a
        condiciones particulares que en cada momento puedan establecerse en B1
        B2 Academy. Dichas condiciones pueden completar, modificar o sustituir,
        en su caso, las Condiciones Generales.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        2.4. El Cliente que realice algún pago en la Plataforma a través de
        Stripe acepta los acuerdos legales para los servicios de Stripe.
      </Text>
      <Text lighter tag="p" color="blue">
        2.5. El Cliente se compromete a la observancia y cumplimiento riguroso
        de las cláusulas indicadas en la Plataforma, así como de cualquier
        disposición legal que fuera de aplicación.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        2.6. B1 B2 Academy se reserva el derecho de cambiar las presentes
        condiciones generales de contratación en cualquier momento, por lo que
        corresponde al Cliente la revisión de éstas antes de contratar productos
        o servicios disponibles en la Plataforma.
      </Text>
      <Text lighter tag="p" color="blue">
        3. Descripción del servicio A continuación, se detallan los servicios
        que B1 B2 Academy ofrece en la actualidad a los Estudiantes a través de
        la Plataforma. En cualquier caso, B1 B2 Academy se reserva la
        posibilidad de ofrecer a los Estudiantes, en cada momento, nuevos
        servicios o modificar los servicios existentes.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        3.1 Mi cuenta <br /> en la vista del apartado de Mi
        cuenta, el alumno podrá realizar cambios en sus datos personales, así
        como modificar su contraseña o datos de acceso. Las contraseñas son
        encriptadas y dichos datos son tratados conforme a la ley de protección
        de datos vigente.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        3-2 Exámenes <br /> A través de este servicio el alumno
        podrá practicar ejercicios de una forma activa y continuada con la
        supervisión y corrección de un profesor miembro del equipo de B1 B2
        Academy.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        3.3 Mis evaluaciones <br /> A través de este servicio,
        el alumno tendrá un seguimiento de su progreso y recibirá la interacción
        con su tutor de manera instantánea.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        4. Fuero
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        4.1. En el supuesto de que surja algún tipo de conflicto en relación con
        los derechos y obligaciones que dimanan del presente contrato, las
        partes, de común acuerdo pueden establecer su resolución a través del
        sistema de resolución de conflictos y mediación del que dispone la Junta
        de Andalucía en la provincia de Granada en materia de derecho del
        consumidor o cualquier otro que le sustituya, con renuncia a cualquier
        otro fuero.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        4.2. En caso de no alcanzar un acuerdo mediante el procedimiento de
        mediación, la controversia será resuelta por los Juzgados y Tribunales
        de Granada (España), renunciando expresamente a cualesquiera otros que
        pudiera también corresponderles.
      </Text>
      <Text lighter tag="p" color="blue">
        5. Idioma y territorio.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        5.1. Las presentes condiciones generales de contratación están
        redactadas exclusivamente en español.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        5.2. La contratación de productos o servicios disponibles en la
        Plataforma está exclusivamente dirigida al territorio español.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        5.3. Para la contratación de productos o servicios disponibles en la
        Plataforma fuera del territorio español deberá ser consultado
        previamente por el Cliente a Formación Jurídica para su aceptación.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        5. Forma de pago y precio
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        5.1. Para la contratación de productos o servicios disponibles en la
        Plataforma se permiten los siguientes métodos de pago:
        <ul>
          <li>Transferencia bancaria.</li>
          <li>Ingreso en cuenta bancaria.</li>
          <li>Cuenta de Stripe.</li>
          <li>Tarjetas bancarias procesadas a través de Stripe.</li>
        </ul>
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        5.2. Cualquier otro método de pago deberá ser consultado previamente por
        el Estudiante a B1 B2 Academy para su aceptación.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        5.3. Los precios aplicables a los productos o servicios disponibles en
        la Plataforma serán los que figuren en la Plataforma, así como en la
        pagina web.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        5.4. Los precios que figuran en la Plataforma están exentos del Impuesto
        de Valor Añadido (IVA) en virtud del artículo 20, punto 0 de la Ley IVA
        37/1992
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        7. Desistimiento.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        7.1. El Estudiante que tenga la consideración de consumidor o usuario
        según lo establecido en el Real Decreto Legislativo 1/2007, de 16 de
        noviembre, por el que se aprueba el texto refundido de la Ley General
        para la Defensa de los Consumidores y Usuarios y otras leyes
        complementarias, tendrá derecho a desistirse del contrato durante un
        periodo de 2 días naturales sin indicar el motivo.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        8. Responsabilidades.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        8.1. B1 B2 Academy garantiza al Estudiante que llevará a cabo todas las
        acciones que estén en su mano para el correcto funcionamiento de la
        Plataforma.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        8.2. La responsabilidad de B1 B2 Academy en relación con la contratación
        de los productos o servicios disponibles en la Plataforma estará
        limitada estrictamente, en cualquier caso, al precio estipulado en la
        contratación.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        8.3 Así mismo, B1 B2 Academy no se hará cargo de compensaciones
        económicas más que la devolución del importe abonado por Estudiante
        siempre y cuando la caída no se prolongue por más de 48 horas en el mes
        facturado.
      </Text>
      <Text lighter tag="p" color="blue">
        8.4 B1 B2 Academy garantiza al Cliente la sostenibilidad de la
        plataforma siempre y cuando estás no estén frustradas por cambios
        causados por tecnologías o servidores que utilizan los códigos de
        programación.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        9. Proceso de matriculaciones de cursos de formación.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        9.1. B1 B2 Academy se reserva el derecho a no aceptar solicitudes de
        inscripción o matriculaciones, comunicando tales circunstancias a los
        Estudiantes a través de correo electrónico.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        10. Certificados y diplomas de los cursos. Tras la realización de los
        cursos no se expedirá ninguna certificación académica, ya que B1 B2
        Academy es un centro de formación no reglada y por ende no está
        autorizado por la Consejería de Educación y Ciencia de la Junta de
        Andalucía, ni por otra administración Publica, ya que los cursos que
        imparten no pertenecen a la misma.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        11.1. Los términos y condiciones pactados en el presente Acuerdo no
        implican, implícita ni explícitamente, la cesión de ninguno de los
        derechos sobre la propiedad industrial e intelectual del Software, sus
        manuales o el modelo de datos. Los conocimientos y el know-how inherente
        al Software, así como los conocimientos utilizados para la configuración
        del mismo, son información propia y confidencial de B1 B2 Academy.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        11.2. El Estudiante asume la responsabilidad por los daños efectivos que
        B1 B2 Academy sufra por causa directa del uso fraudulento o copia ilegal
        de los programas o de estas informaciones.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        11.3. Alumno deberá respetar los avisos de copyright que aparezcan en el
        programa o en la documentación original.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        11.5. El contenido almacenado en la plataforma será propiedad de sus
        respectivos autores.
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        12. Política de Protección de Datos
        <br />
        B1 B2 Academy, siguiendo la normativa vigente de Protección de Datos de
        Carácter Personal (Ley reguladora de la privacidad de datos, Ley 15/1999
        de 13 de diciembre), informa:
      </Text>
      <br />
      <Text lighter tag="p" color="blue">
        12.1 Que los datos recogidos a través de los formularios situados en
        www.aptisgo.b1b2.es se incluyen en ficheros automatizados de datos
        propiedad de B1 B2 Academy, con la finalidad de poder prestar los
        servicios contratados por el Estudiante, poder facilitarle cualquier
        información que éste requiera de una forma personalizada o remitir
        cualquier información.
        <br />
        12.2. Que los datos recogidos a través del servicio sobre los usuarios
        se incluyen en ficheros automatizados, cuyo responsable del fichero y
        titular es B1 B2 Academy.
        <br />
        12.3. B1 B2 Academy garantiza la confidencialidad de todos los datos
        recogidos adoptando las medidas técnicas que sean necesarias para
        garantizar la seguridad e integridad de las mismas.
        <br />
        12.4. B1 B2 Academy se compromete a no vender, ceder o transferir los
        datos recogidos bajo ningún concepto. No obstante, B1 B2 Academy
        revelará a las autoridades públicas competentes los Datos Personales o
        cualquier otra información que sea requerida de conformidad a las
        disposiciones legales y reglamentarias aplicables en cada caso.
        <br />
        12.5. B1 B2 Academy asegura que protegerá la confidencialidad de los
        correos electrónicos intercambiados con el Estudiante. El estudiante se
        obliga a cumplir con el mismo nivel de confidencialidad con los correos
        electrónicos recibidos de B1 B2 Academy.
        <br />
        12.6. El Estudiante tiene reconocidos sus derechos, y podrá ejercitar
        sus derechos de acceso, cancelación, rectificación y oposición en
        cualquier momento, solicitándolo por correo certificado a la dirección
        postal.
        <br />
        12.7. El Estudiante garantiza y responde, en cualquier caso, de la
        veracidad, exactitud, vigencia y autenticidad de los Datos Personales
        facilitados, y se compromete a mantenerlos debidamente actualizados.
        <br />
        12.8. El Estudiante autoriza de forma inequívoca a B1 B2 Academy para
        realizar el tratamiento informático de los datos facilitados bajo las
        condiciones descritas en este documento exclusivamente para poder
        facilitar y facturar de forma correcta los servicios contratados.
        <br />
        12.9. Todos los datos de carácter personal se encuentran alojados en
        servidores ubicados dentro de la Unión Europea.
        <br />
        12.10. Las copias de seguridad se encuentran alojados en servidores
        ubicados dentro de la Unión Europea.
        <br />
        12.11. En base al artículo 12 de la Ley 15/1999 LOPD no se considera
        comunicación de datos el acceso de B1 B2 Academy a los datos para la
        prestación que el Estudiante está contratando.
        <br />
        12.12. En base al artículo 12 de la Ley 15/1999 LOPD, B1 B2 Academy
        únicamente tratará los datos conforme a las instrucciones del
        estudiante, que no los aplicará o utilizará con un fin distinto al que
        figure en este contrato, ni los comunicará, ni siquiera para su
        conservación, a otras personas.
        <br />
        12.13. Una vez cumplida esta prestación contractual, los datos de
        carácter personal aportados por el Estudiante en la plataforma deberán
        ser destruidos o devueltos al Estudiante, al igual que cualquier soporte
        o documentos en que conste algún dato de carácter personal objeto del
        tratamiento para la prestación del servicio.
        <br />
        12.14. Todas las medidas de seguridad en base al cumplimiento de la
        vigente Ley de Protección de Datos de Carácter Personal 15/1999 se
        encuentran en el artículo 12 “Medidas de Seguridad” de este contrato.
        <br />
        <br />
        12.15 Mailing
        <br /> <br />
        1.1 Mediante el acceso a la plataforma, los Estudiantes proporcionarán
        de forma consentida un correo electrónico personal. Dicho correo
        electrónico será añadido a la lista de suscripción de la plataforma. En
        dicha lista de suscripción, el Cliente recibirá correos electrónicos
        procedentes del dominio de la plataforma. B1B2 Academy garantiza al
        Alumno que toda información percibida en dicha suscripción será
        estrictamente relevante a la actividad de la plataforma.
        <br />
        1.2 El Alumno podrá, siempre que lo desee, cancelar la suscripción del
        Mailing administrado por la plataforma. Dicha cancelación pondrá fin a
        todo contacto por correo electrónico por parte de B1 B2 Academy a
        excepción de los correos automáticos generados por la aplicación
        referentes al acceso y la recuperación de sus contraseñas.
        <br />
        <br />
        Sistema de Hojas Electrónicas de Quejas y Reclamaciones.
        <br />
        <br />
        13.1. El sistema de Hojas Electrónicas de Quejas y Reclamaciones de la
        Junta de Andalucía permite presentar una hoja de quejas y reclamaciones
        de la misma manera a como se haría en formato papel, pero a través de
        Internet, en formato electrónico.
        <br />
        13.2. Formación Jurídica se encuentra adherido al sistema de Hojas
        Electrónicas de Quejas y Reclamaciones de la Junta de Andalucía.
        <br />
        14. Política de cookies de web
        <br />
        El sitio web www.b1b2.es utiliza cookies propias y de terceros para
        recopilar información que ayuda a optimizar su visita a sus páginas web.
        No se utilizarán las cookies para recoger información de carácter
        personal. Usted puede permitir su uso o rechazarlo, también puede
        cambiar su configuración siempre que lo desee. Encontrará más
        información en nuestra Política de Cookies contenida en
        <Text
          tag="a"
          href="https://www.b1b2.es/politica-de-cookies/"
          color="info"
        >
          https://www.b1b2.es/politica-de-cookies/
        </Text>
      </Text>
    </React.Fragment>
  )
}

export default TermsAndConditions
