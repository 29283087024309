export const classesEN = {
  activeMeeting: "Call on course, enter",
  available: "Booked classes",
  counter: "Counter",
  DESCRIPTION: {
    confirm: " Confirm a one to one class just for you",
    customize: "Personalize your classes",
    guide: "Sometimes a bit of guidance is all we need!",
    minutes: "one 45 minute class",
    payments: "Safe payments with Stripe",
    prepare: "Prepare for specific skills of the test",
    review: "Check the schedules",
    teacher: "With a teacher expert in {{model}}",
    title: "Book your class in seconds"
  },
  history: 'History',
  purchase: "Purchase a package",
  schedules: "Check schedules",
  subscriptions: "Booked classes",
  title: "My classes",
  unavailableHistory: "No available data in your history",
  unavailableSubscriptions: "You do not have any booked classes"
};