import React, { useCallback } from 'react'
import { Row, Col, Container, Card, Button } from 'react-bootstrap'
import lang from 'lang'

import Emoji from 'components/Emoji'
import Template from 'components/Template'
import Text from 'components/Text'

import 'assets/css/error404.css'

const colStyle = {
  fontSize: '8rem'
}

const languageFallbackSupport = {
  default: {
    main: lang.t('DEFAULT.404.main'),
    back: lang.t('DEFAULT.404.back')
  }
}

const Error404 = ({ history }) => {
  const redirect = useCallback(() => {
    history.push('/account/dashboard')
  }, [history])

  return (
    <Template withNavbar={false} withAnimationType="bounceInDown">
      <Container fluid>
        <Row className="h-100 justify-content-center align-items-center">
          <Col xs={12}>
            <Card className="error-style">
              <Card.Body>
                <Row className="align-items-center ">
                  <Col xs={6} className="text-center">
                    <Emoji name="Alien" width={150} />
                  </Col>
                  <Col xs={6} className="text-center" style={colStyle}>
                    404
                  </Col>
                  <Col xs={12}>
                    <Text center tag="h4">
                      {languageFallbackSupport.default.main}
                    </Text>
                  </Col>
                  <Col xs={12} className="text-center">
                    <Button className="btn-style px-5" onClick={redirect}>
                      {languageFallbackSupport.default.back}
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Template>
  )
}

export default Error404
