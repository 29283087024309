import httpClient, { GET, PATCH } from 'providers/http'

/**
 * @param {{ signal?: AbortSignal }}
 */
export function fetchModels({ signal }) {
  return httpClient({
    endpoint: '/api/v1/models',
    method: GET,
    requiresAuth: true,
    signal
  })
}

/**
 * @param {{ name: string, signal: AbortSignal}}
 */
export function fetchModel({ name, signal }) {
  return httpClient({
    endpoint: '/api/v1/models',
    method: GET,
    requiresAuth: true,
    signal,
    queries: {
      name
    }
  })
}

export async function patchModel({ name }) {
  return httpClient({
    endpoint: '/api/v1/models',
    method: PATCH,
    queries: {
      name
    },
    requiresAuth: true
  })
}
