export const classesES = {
  activeMeeting: "Llamada en curso, entrar",
  available: "Clases abonadas disponibles",
  counter: "Contador",
  DESCRIPTION: {
    confirm: " Confirma una clase particular sólo para ti",
    customize: "Personaliza tu clase",
    guide: "A veces una guía, es todo lo que nos hace falta",
    minutes: "1 clase 45 minutos",
    payments: "Pagos seguros con Stripe",
    prepare: "Prepara competencias específicas del examen",
    review: "Revisa los horarios sin compromiso",
    teacher: "Con un profesor experto en {{model}}",
    title: "Reserva tu clase en segundos"
  },
  history: 'Historial',
  purchase: "Adquirir un paquete",
  schedules: "Consultar horarios",
  subscriptions: "Clases reservadas",
  title: "Mis clases",
  unavailableHistory: "No tienes dato sobre el historial",
  unavailableSubscriptions: "No tienes clases reservadas"
};
