import httpClient, { GET, POST, PATCH, PUT } from 'providers/http'



/**
 * @param {AbortableRequest}
 */
export async function fetchExams ({ model, signal }) {
  return await httpClient({
    endpoint: '/api/v1/exams',
    method: GET,
    signal,
    queries: {
      model
    },
    requiresAuth: true
  })
}

/**
 * @param {AbortableRequest}
 */
export async function fetchExam ({ signal, id }) {
  return await httpClient({
    endpoint: '/api/v1/exams',
    method: GET,
    signal,
    params: [id],
    requiresAuth: true
  })
}

/**
 * @param {AbortableRequest}
 */
export async function createProgress (data) {
  return await httpClient({
    body: data,
    endpoint: '/api/v1/progress',
    method: POST,
    requiresAuth: true
  })
}

/**
 * @param {AbortableRequest}
 */
export async function fetchProgress (queries) {
  return await httpClient({
    endpoint: '/api/v1/progress',
    method: GET,
    queries,
    requiresAuth: true
  })
}

/**
 * @param {AbortableRequest}
 */
export async function updateProgress ({ formData }) {
  return await httpClient({
    body: formData,
    endpoint: '/api/v1/progress',
    method: PUT,
    normalize: true,
    raw: true,
    requiresAuth: true
  })
}


export async function patchProgress (queries) {
  return await httpClient({
    body: {
      update: true
    },
    endpoint: '/api/v1/progress',
    method: PATCH,
    queries,
    requiresAuth: true
  })
}