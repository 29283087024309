import httpClient, { GET } from 'providers/http'

export function fetchYoutubeVideos ({ signal }) {
  return httpClient({
    endpoint: '/api/v1/youtube',
    method: GET,
    requiresAuth: true,
    signal
  })
}

