import React, { memo } from 'react'
import { Redirect } from 'react-router-dom'

import useAuthProvider from 'hooks/useAuthProvider'
import useModels from 'hooks/useModels'

import Checkpoint from 'views/mails/Checkpoint'

import FallbackMode from 'components/FallbackMode'

/**
 * @param {React.Component} Component
 */
const withVerification = Component => props => {
  const user = useAuthProvider()

  return user.profile.isVerified ? <Component {...props} /> : <Checkpoint />
}

/**
 * @param {React.Component} Component
 */
const withLogout = Component =>
  memo(props => {
    const user = useAuthProvider()

    return user.isLoggedIn ? <Redirect to="/" /> : <Component {...props} />
  })

/**
 * @param {React.Component} Component
 */
const withAdmin = Component => props => {
  const user = useAuthProvider()

  if (user.profile && user.profile.role.name === 'Admin') {
    return <Component {...props} />
  }

  return <Redirect to="/" />
}


const withModels = Compoonent => props => {
  const { model } = useModels()

  if (model) {
    return <Compoonent {...props} />
  }

  return <FallbackMode />
}


export { withVerification, withLogout, withAdmin, withModels }


