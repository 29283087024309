import httpClient, { GET, POST } from 'providers/http'


export async function fetchPackages({ signal }) {
  return await httpClient({
    endpoint: '/api/v1/packages',
    method: GET,
    queries: {
      active: true
    },
    requiresAuth: true,
    signal
  })
}

/**
 * @param {{ paymentMethodId: {}, requiresAction: boolean, cancel: boolean }}
 */
export async function createPackage({
  cancel,
  paymentMethodId,
  planId,
  requiresAction
}) {
  return await httpClient({
    body: {
      cancel: cancel || false,
      paymentMethodId,
      requiresAction: requiresAction || false
    },
    endpoint: '/api/v1/packages',
    method: POST,
    queries: {
      planId
    },
    requiresAuth: true
  })
}


/**
 * @param {{ planId: number, userId: number }} data 
 */
export function assignPackage (data) {
  return httpClient({
    endpoint: '/api/v1/packages/assign',
    method: POST,
    queries: data,
    requiresAuth: true
  })
}