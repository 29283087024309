export const termsES = {
  data: `
    DATOS IDENTIFICATIVOS DEL SERVICIO B1 B2 ACADEMY Loto Gómez-Aleixandre
    Sandoval Avd. Constitución nº18 ESC04 PL01 18012 GRANADA Web:
    www.b1b2.es Acceso plataforma
  `,
  '1': `
    1. Acceso a la Plataforma Las presentes condiciones generales (en
    adelante, las “Condiciones Generales”) regulan el servicio de cursos de
    inglés on-line que ofrece B1 B2 Academy (en adelante, el “Servicio” o
    “B1 B2 Academy”) en su plataforma tecnológica accesible a través de su
    aplicación móvil y sitio web (en adelante, la “Plataforma”). Mediante el
    acceso a la Plataforma “AptisGo”, los Estudiantes que se den de alta (en
    adelante, los “Estudiantes”) tendrán acceso a los cursos de inglés
    on-line disponibles en cada momento. La persona que acceda a la
    Plataforma con el fin de contratar productos o servicios disponibles en
    la misma, asegura que tiene capacidad legal para ello.
  `,
  '2': '2. El Objeto',
  '2.1':
    '2.1. Se consideran productos o servicios disponibles en la Plataforma, todos los ofertados en la Plataforma.',
  '2.2': `
    2.2. Mediante la contratación de productos o servicios disponibles en la
    Plataforma, el Cliente acepta expresamente todas las cláusulas de estas
    condiciones generales de contratación, de su aviso legal, de su política
    de privacidad, y de su política de cookies, así como de todas aquellas
    condiciones particulares recogidas para la contratación de determinados
    productos o servicios disponibles en la Plataforma. En caso de no
    aceptar alguna de las citadas cláusulas, el Cliente deberá abstenerse de
    contratar productos o servicios en la Plataforma.
  `,
  '2.3': `
    2.3 Algunos de los servicios ofertados pueden estar sujetos a
    condiciones particulares que en cada momento puedan establecerse en B1
    B2 Academy. Dichas condiciones pueden completar, modificar o sustituir,
    en su caso, las Condiciones Generales.
  `,
  '2.4': '2.4. El Cliente que realice algún pago en la Plataforma a través de Stripe acepta los acuerdos legales para los servicios de Stripe.',
  '2.5': `
    2.5. El Cliente se compromete a la observancia y cumplimiento riguroso
    de las cláusulas indicadas en la Plataforma, así como de cualquier
    disposición legal que fuera de aplicación.
  `,
  '2.6': `
    2.6. B1 B2 Academy se reserva el derecho de cambiar las presentes
    condiciones generales de contratación en cualquier momento, por lo que
    corresponde al Cliente la revisión de éstas antes de contratar productos
    o servicios disponibles en la Plataforma.
  `,
  '3': `
    3. Descripción del servicio A continuación, se detallan los servicios
    que B1 B2 Academy ofrece en la actualidad a los Estudiantes a través de
    la Plataforma. En cualquier caso, B1 B2 Academy se reserva la
    posibilidad de ofrecer a los Estudiantes, en cada momento, nuevos
    servicios o modificar los servicios existentes.
  `,
  '3.1': `
    3.1. Mi cuenta: en la vista del apartado de Mi
    cuenta, el alumno podrá realizar cambios en sus datos personales, así
    como modificar su contraseña o datos de acceso. Las contraseñas son
    encriptadas y dichos datos son tratados conforme a la ley de protección
    de datos vigente.
  `,
  '3.2': `
    3.2. Exámenes: A través de este servicio el alumno
    podrá practicar ejercicios de una forma activa y continuada con la
    supervisión y corrección de un profesor miembro del equipo de B1 B2
    Academy.
  `,
  '3.3': `
    3.3. Mis evaluaciones: A través de este servicio,
    el alumno tendrá un seguimiento de su progreso y recibirá la interacción
    con su tutor de manera instantánea.
  `,
  '4.1': `
    4.1. En el supuesto de que surja algún tipo de conflicto en relación con
    los derechos y obligaciones que dimanan del presente contrato, las
    partes, de común acuerdo pueden establecer su resolución a través del
    sistema de resolución de conflictos y mediación del que dispone la Junta
    de Andalucía en la provincia de Granada en materia de derecho del
    consumidor o cualquier otro que le sustituya, con renuncia a cualquier
    otro fuero.
  `,
  '4.2': `
    4.2. En caso de no alcanzar un acuerdo mediante el procedimiento de
    mediación, la controversia será resuelta por los Juzgados y Tribunales
    de Granada (España), renunciando expresamente a cualesquiera otros que
    pudiera también corresponderles.
  `,
  '5': '5. Idioma y territorio.',
  '5.1': `
    5.1. Las presentes condiciones generales de contratación están
    redactadas exclusivamente en español.
  `,
  '5.2': `
    5.2. La contratación de productos o servicios disponibles en la
    Plataforma está exclusivamente dirigida al territorio español.
  `,
  '5.3': `
    5.3. Para la contratación de productos o servicios disponibles en la
    Plataforma fuera del territorio español deberá ser consultado
    previamente por el Cliente a Formación Jurídica para su aceptación.
  `,
  '6': '6. Forma de pago y precio',
  '6.1': '6.1. Para la contratación de productos o servicios disponibles en la Plataforma se permiten los siguientes métodos de pago:',
  transferAccount: 'Transferencia bancaria',
  bankAccount: 'Ingreso en cuenta bancaria',
  stripeAccount: 'Cuenta de Stripe',
  cardsAccount: 'Tarjetas bancarias procesadas a través de Stripe',
  '6.2': '6.2. Cualquier otro método de pago deberá ser consultado previamente por el Estudiante a B1 B2 Academy para su aceptación.',
  '6.3': '6.3. Los precios aplicables a los productos o servicios disponibles en la Plataforma serán los que figuren en la Plataforma, así como en la pagina web.',
  '6.4': `
    6.4. Los precios que figuran en la Plataforma están exentos del Impuesto de Valor Añadido (IVA) en virtud del artículo 20, punto 0 de la Ley IVA 37/1992
  `,
  '7': 'Desestimiento',
  '7.1': `
    7.1. El Estudiante que tenga la consideración de consumidor o usuario
    según lo establecido en el Real Decreto Legislativo 1/2007, de 16 de
    noviembre, por el que se aprueba el texto refundido de la Ley General
    para la Defensa de los Consumidores y Usuarios y otras leyes
    complementarias, tendrá derecho a desistirse del contrato durante un
    periodo de 2 días naturales sin indicar el motivo.
  `,
  '8': '8. Responsabilidades.',
  '8.1': '8.1. B1 B2 Academy garantiza al Estudiante que llevará a cabo todas las acciones que estén en su mano para el correcto funcionamiento de la Plataforma.'  
}
