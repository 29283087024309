import httpClient, { GET } from 'providers/http'

const endpoint = '/api/v1/stats'
/**
 * @returns {Promise<HttpRequest>}
 */
export async function getStats ({ model }) {
  return await httpClient({
    endpoint,
    method: GET,
    queries: {
      model
    },
    requiresAuth: true
  })
}