export const img = {
  "1icon": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/1icon.png",
  "2icon": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/2icon.png",
  "3icon": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/3icon.png",
  "4icon": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/4icon.png",
  "5icon": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/5icon.png",
  "6icon": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/6icon.png",
  "7icon": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/7icon.png",
  "abc": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/abc.png",
  "add": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/add.png",
  "advertising": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/advertising.png",
  "album": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/album.png",
  "alien": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/alien.png",
  "angry": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/angry.png",
  "aptisgo": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/aptisgo.svg",
  "arrow": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/arrow.png",
  "astronaut": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/astronaut.png",
  "audio-message": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/audio-message.png",
  "audio": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/audio.png",
  "australia": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/australia.png",
  "awesome": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/awesome.png",
  "banner-img": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/banner-img.svg",
  "basketball-player": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/basketball-player.png",
  "bee": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/bee.png",
  "biker": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/biker.png",
  "book": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/book.png",
  "books": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/books.png",
  "boy": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/boy.png",
  "bubble": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/bubble.png",
  "calendar": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/calendar.png",
  "calendarClass": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/calendarClass.png",
  "cancel": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/cancel.png",
  "catfaq": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/catfaq.png",
  "certificate": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/certificate.png",
  "chat": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/chat.png",
  "chatheart": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/chatheart.png",
  "check-mark": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/check-mark.png",
  "check": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/check.png",
  "checked": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/checked.png",
  "cheerleader": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/cheerleader.png",
  "circle": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/circle.png",
  "coffee": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/coffee.png",
  "complete": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/complete.png",
  "completed-task": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/completed-task.png",
  "completed": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/completed.png",
  "confetti": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/confetti.png",
  "core": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/core.png",
  "course": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/course.png",
  "cowboy": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/cowboy.png",
  "date": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/date.png",
  "degree-hat": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/degree-hat.png",
  "development": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/development.png",
  "dialog": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/dialog.svg",
  "diet": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/diet.png",
  "dog": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/dog.png",
  "done": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/done.png",
  "emo": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/emo.png",
  "employee": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/employee.png",
  "error": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/error.png",
  "evaluation": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/evaluation.png",
  "eye": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/eye.png",
  "faq": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/faq.png",
  "feedback": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/feedback.png",
  "file": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/file.png",
  "filter": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/filter.png",
  "fisherman": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/fisherman.png",
  "flag": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/flag.png",
  "flower": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/flower.png",
  "folder": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/folder.png",
  "frog": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/frog.png",
  "gato": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/gato.png",
  "gift-card": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/gift-card.png",
  "girl-1": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/girl-1.png",
  "girl": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/girl.png",
  "graduation-diploma": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/graduation-diploma.png",
  "grammar": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/grammar.png",
  "happy": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/happy.png",
  "hard-work": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/hard-work.png",
  "help": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/help.png",
  "hipster": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/hipster.png",
  "homework": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/homework.png",
  "image": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/image.png",
  "incorrect-mark": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/incorrect-mark.png",
  "index": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/index.js",
  "internet-off": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/internet-off.png",
  "Josh": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/Josh.png",
  "journal": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/journal.png",
  "king": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/king.png",
  "knight": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/knight.png",
  "library": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/library.png",
  "listenig-emoji": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/listenig-emoji.png",
  "listening": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/listening.png",
  "lock": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/lock.png",
  "locked": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/locked.png",
  "logo-APTIS": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/logo-APTIS.png",
  "logo-darkmode": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/logo-darkmode.png",
  "logo": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/logo.png",
  "love": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/love.png",
  "mark": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/mark.png",
  "marked": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/marked.png",
  "megaphone": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/megaphone.png",
  "microphone": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/microphone.png",
  "money": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/money.png",
  "mug": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/mug.png",
  "octopus": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/octopus.png",
  "online-class": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/online-class.png",
  "online-learning": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/online-learning.png",
  "pending": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/pending.png",
  "person": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/person.png",
  "pilot": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/pilot.png",
  "pizza": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/pizza.png",
  "play": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/play.png",
  "positive-review": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/positive-review.png",
  "princess": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/princess.png",
  "professor": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/professor.png",
  "questions": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/questions.png",
  "rapper": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/rapper.png",
  "rating": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/rating.png",
  "reading": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/reading.png",
  "remaining": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/remaining.png",
  "report": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/report.png",
  "responsive": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/responsive.png",
  "review": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/review.png",
  "right": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/right.png",
  "robot": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/robot.png",
  "runner": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/runner.png",
  "schedule": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/schedule.png",
  "school": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/school.png",
  "search": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/search.png",
  "send": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/send.png",
  "settings": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/settings.png",
  "shocked": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/shocked.png",
  "sound-speaking": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/sound-speaking.png",
  "sound": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/sound.png",
  "spaceship": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/spaceship.png",
  "speaker-off": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/speaker-off.png",
  "speaker": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/speaker.png",
  "speaking": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/speaking.png",
  "stat": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/stat.png",
  "student (2)": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/student (2).png",
  "student": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/student.png",
  "studying": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/studying.png",
  "sun": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/sun.png",
  "tarjeta": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/tarjeta.png",
  "teacher": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/teacher.png",
  "test (5)": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/test (5).png",
  "test": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/test.png",
  "tick": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/tick.png",
  "time": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/time.png",
  "typewriter": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/typewriter.png",
  "unchecked": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/unchecked.png",
  "Unidad-1": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/Unidad-1.png",
  "Unidad-10": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/Unidad-10.png",
  "Unidad-11": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/Unidad-11.png",
  "Unidad-12": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/Unidad-12.png",
  "Unidad-13": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/Unidad-13.png",
  "Unidad-14": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/Unidad-14.png",
  "Unidad-15": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/Unidad-15.png",
  "Unidad-3": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/Unidad-3.png",
  "Unidad-4": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/Unidad-4.png",
  "Unidad-5": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/Unidad-5.png",
  "Unidad-6": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/Unidad-6.png",
  "Unidad-7": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/Unidad-7.png",
  "Unidad-8": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/Unidad-8.png",
  "Unidad-9": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/Unidad-9.png",
  "Unindad-2": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/Unindad-2.png",
  "unit-completed": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/unit-completed.png",
  "usability": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/usability.png",
  "user-male": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/user-male.png",
  "user": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/user.png",
  "viking": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/viking.png",
  "volume": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/volume.png",
  "wav": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/wav.png",
  "whatsapp": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/whatsapp.png",
  "wifi": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/wifi.png",
  "writing": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/writing.png",
  "zoom": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/zoom.png",
  "sidebar": "https://dkmwdxc6g4lk7.cloudfront.net/assets/img/sidebar.png"
}