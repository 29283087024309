import httpClient, { GET } from 'providers/http'


const endpoint = '/api/v1/feedback'


export async function getFeedback ({ signal, ...queries }) {
  return await httpClient({
    endpoint,
    method: GET,
    queries,
    requiresAuth: true,
    signal
  })
}